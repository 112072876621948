<template>
  <div
    class="container inside-wrapper blog"
    id="news"
    data-aos="fade-up"
    data-aos-duration="1000"
  >

    <div class="row back-image back-blue" style="">
      <div class="col-md-5 col-heading">
        <h2 class="heading wow">
          News <span class="lnr lnr-map"></span>
        </h2>
      </div>
    </div>

    <div class="row news-container">
      <div class="col-sm-12 loading" style="height: 700px" v-if="news_data === undefined && !news_error">
        <Loading />
      </div>
      <template v-if="news_data">
        <div class="col-md-6 news-item" v-for="news in news_data.data" :key="news.id">
          <div class="img-box">
            <router-link :to="'/news/'+news.slug">
              <div class="img-box__img">
                <DefaultImage :id="news.image" width="100%" height="100%" />
              </div>
            </router-link>
          </div>
          <div class="blog-read col-pad">
            <router-link :to="'/news/'+news.slug">
              <h2 class="text-red text-bold">
                {{news.title}}
              </h2>
            </router-link>
            <p class="text-dark p-margin text-justify">
              {{trimMyString(news.description, 170)}}
            </p>
          </div>
        </div>
      </template>
    </div>

    <div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/work">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Work
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/contact">
          <h2 class="heading text-light">
            Contact <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "../services/api";
  import useSWRV from "swrv";
  import DefaultImage from "../components/default-image";
  import moment from "moment";
  import Loading from "../components/Loading";

  export default {
    name: "News",
    components: {Loading, DefaultImage},
    setup() {
      let {
        data: news_data,
        error: news_error,
        isValidating: news_isValidating
      } = useSWRV(() => `items/news?status=published&sort=-created_on`, api.fetcher);

      return {
        news_data,
        news_error,
        news_isValidating,
      }
    },
    created() {
      window.scrollTo(0, 0);
    },
    methods: {
      moment (date) {
        return moment(date).format('MMM DD, YYYY');
      },
      trimMyString(string, maxLength, start = 0) {
        if (string.length > maxLength) {
          let trimmedString = string.substr(start, maxLength);
          return (
              trimmedString.substr(
                  start,
                  Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
              ) + ' ...'
          )
        }
        return string
      },
    }
  };
</script>

<style scoped>
  .news-container {
    padding: 40px 20px;
  }
  .news-item {
    margin-bottom: 20px;
  }
  #news {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  #news .back-blue {
    background-image: url("../assets/img/news-bg.png");
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: bottom right;
  }
  #news .back-blue .heading {
    padding: 10px 0 30px;
  }
  #news .blog-read {
    padding: 20px 0;
  }
  .blog .blog-read h2 {
    border-bottom: none;
    font-size: 23px;
    line-height: 1.4;
    padding-bottom: 0;
  }
  @media (min-width: 992px) {
    .news-item {
    height: 472px;
    }  
  }
</style>
